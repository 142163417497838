/**
 * É um array de módulos em que o conteúdo é um array que no primeiro indice é o nome e no segundo as dependencias dele, Ex:
 * ["diferencial", ["realties"]] -> `diferencial` depende de realties para ter acesso.
 */
export const modules = [
  ['realties'],
  ['messages'],
  ['clients'],
  ['companies'],
  ['brokers'],
  ['agencies', ['companies']],
  ['crms', ['companies']],
  ['persons', ['companies', 'brokers']],
  ['campaigns'],
  ['leadAdsForms'],
  ['leadsSubmission', ['leadAdsForms']],
  ['services', ['configs']],
  ['reports'],
  ['highlights', ['realties']],
  ['homeApto', ['configs']],
  ['attributes'],
  ['locations'],
  ['jobPositions'],
  ['railStation', ['configs']],
  ['frequentlyAskedQuestions'],
  ['propertyIndexes', ['configs']],
  ['institutionals'],
  ['financialInstitutions', ['configs']],
  ['savedSearches', ['configs']],
  ['feeds', ['reports']],
  ['customization', ['configs']],
  ['vince', ['configs']],
  ['users'],
  ['debug', ['configs']],
  ['logs'],
  ['configs'],
  ['cortex'],
  ['coupons'],
]
